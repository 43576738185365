import { useStaticQuery, graphql } from "gatsby"
export const useSiteQuery = () => {
  const {
    allContentfulStlGlobalSite: { edges },
  } = useStaticQuery(
    graphql`
      query globalSite {
        allContentfulStlGlobalSite {
          edges {
            node {
              id
              node_locale
              contentful_id
              logo {
                gatsbyImageData(quality: 75, formats: WEBP)
                title
              }
              header {
                __typename
                ...componentMenuGroup
                ...componentLink
              }
              footer {
                ... on ContentfulComponentMenuGroup {
                  childrenItems {
                    ...componentLink
                  }
                }
              }
              seoImage {
                file {
                    url
                }
              }
            }
          }
        }
      }
    `
  )

  const localData = edges.filter(({ node }) => node.node_locale === 'ja-JP')
    const data = localData.reduce((acc, val) => ({ ...acc, ...val.node }), {})
    return data
}
