import React from 'react'

import styled from 'styled-components'

import {
    colors,
    fonts
} from '../../../styles/index'

import { Link } from 'gatsby'
import { useLocalPath } from '../../../hooks'

const HeaderLink = ({
    data
}) => {
    if (data.target) {
        return (
            <Linking>
                <Link to={useLocalPath(data.target.slug)}>
                    {data.title}
                </Link>
            </Linking>
        )
    } else if (data.slug) {
        return (
            <Linking>
                <Link to={useLocalPath(data.slug)}>
                    {data.title}
                </Link>
            </Linking>
        )
    } else if (data.externalLink) {
        return (
            <Linking>
                <a href={data.externalLink}>
                    {data.title}
                </a>
            </Linking>
        )
    } else {
        return (
            <p>{data.title}</p>
        )
    }
}

const Linking = styled.div`
    a {
        font-size: 14px;
        color: ${colors.white};
        font-family: ${fonts.bold};
        text-decoration: none;
        letter-spacing: -1px;
        font-weight: bold;
        line-height: 19px;
    }
`

export default HeaderLink