import React,{useState} from 'react'
import styled from 'styled-components';
import { colors, desktopBp, fonts } from '../../styles';

import { useSiteQuery } from '../../hooks/layout/useSiteQuery'

import HeaderLink from '../Header/HeaderLink/HeaderLink';

import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';
import { useLocation } from '@reach/router';

import Facebook from '../../static/facebook.svg';
import Twitter from '../../static/twitter.svg';
import CookiePopup from "./CookiePopup/CookiePopup"

import * as styles from "./Footerupdate.module.scss";

const Footer = () => {
    const {
        footer
    } = useSiteQuery()

    const [privacyOpen, setPrivacyOpen] = useState(false)

    const { href } = useLocation()

    return (
        <Footer_Container>
            <div className='container container-xl'>
                <Wrapper>
                    {
                        footer.map(({ childrenItems }, index) => (
                            <div key={index}>
                                {
                                    childrenItems.map((item, index) => (
                                        <Item key={index}>
                                            <HeaderLink data={item} />
                                        </Item>
                                    ))
                                }
                                {index===footer.length-1 && (
                                <Item key="Cookieの詳細">
                                   <>
                                   <p className={styles.privacyPopupLink} onClick={e => { setPrivacyOpen(true) }}>Cookieの詳細</p>
                    <div className={`${styles.wrapper} ${privacyOpen ? styles.privacyOpen : ''}`} >
                        {
                            privacyOpen
                                ? <CookiePopup setOpen={setPrivacyOpen} />
                                : null}
                        
                    </div>
                            
                                   </>

                                </Item>
                            )}
                            </div>
                        ))
                    }
                    <Social>
                        <Social_Container_Title>
                            <>このウェブサイトを共有する</>
                        </Social_Container_Title>
                        <Social_Container>
                            <div>
                                <FacebookShareButton url={href}>
                                    <Facebook />
                                </FacebookShareButton>
                            </div>
                            <div>
                                <TwitterShareButton url={href}>
                                    <Twitter />
                                </TwitterShareButton>
                            </div>
                        </Social_Container>
                    </Social>
                </Wrapper>
            </div>
        </Footer_Container>
    )
}

const Footer_Container = styled.footer`
    background: transparent linear-gradient(0deg, #000D0D 0%, #181B22 100%) 0% 0% no-repeat padding-box;
`

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(5, auto);
    padding: 40px 60px;
    column-gap: 40px;
    ${desktopBp}{
        display: flex;
        flex-wrap: wrap;
        padding: 40px 15px;
        justify-content: space-between;
        row-gap: 40px;
        /* grid-template-columns: repeat(2, auto); */
    }
`

const Item = styled.div`
    margin-bottom: 20px;
    line-height: 20px;
    cursor: pointer;
    a{
        font-weight: 500;
    }
    ${desktopBp}{
        p, a{
            font-size: 14px;
        }
    }
`

const Social_Container = styled.div`
    display: flex;
    div:first-child{
        margin-right: 20px;
    }
`

const Social_Container_Title = styled.p`
    color: ${colors.gold};
    font-family: ${fonts.bold};
    font-weight: 500;
    margin-bottom: 35px;
    ${desktopBp}{
        margin-bottom: 40px;
        font-weight: bold;
    }
    font-size: 16px;
`

const Social = styled.div`
    ${desktopBp}{
        width: 100%;
        margin-top: 50px;
    }
`

export default Footer