module.exports = {
    mapLocale: {
        'ja-JP': 'ja',
    },
    cookieLang: {
        'ja-JP': 'jp',
    },
    mapLangSelector: {
        'ja-JP': 'JAPAN',
    },
    mapAddressCountry: {
        'ja-JP': 'JAPAN',
    },
    mapSiteName: {
        'ja-JP': 'Lipton Japan',
    },
    mapFetchifyCode: {
        'ja-JP': 'JPY',
    }
}