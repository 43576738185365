import React from "react"
import { mapLocale } from "../../utils/mapping"
import { useLocale } from "./useLocale";

export const useLocalPath = (slug) => {
    const locale = useLocale()

    if (typeof slug === "undefined" || slug === null) return

    const newSlug = slug[0] === '/' ? slug : `/${slug}`
    if (slug.includes('#')) return `/${mapLocale[locale]}${newSlug}`

    if (slug === '' || slug === '/') return `/${mapLocale[locale]}/`
    if (slug[slug.length - 1] === '/') return `/${mapLocale[locale]}${newSlug}`
    else return `/${mapLocale[locale]}${newSlug}/`
}