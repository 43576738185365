import React from 'react'

import loadable from '@loadable/component'
// import Header from '../Header/Header'
const Header = loadable(() => import("../Header/Header"))
// const Footer = loadable(() => import("../Footer/Footer"))
// import "../../styles/main.scss"
import Footer from '../Footer/Footer'

import { createGlobalStyle } from 'styled-components'
// import "../../styles/fonts/fonts.css"
import { colors, desktopBp, fonts, mobileBp } from '../../styles'
import Noto_Sans_Regular from '../../styles/fonts/NotoSansJP-Regular.otf';
import Noto_Sans_Bold from '../../styles/fonts/NotoSansJP-Bold.otf';

// require('typeface-noto-sans')

const Layout = ({ children }) => {
    const GlobalStyles = createGlobalStyle`

        @font-face {
            font-family: 'noto-regular';
            src: url(${Noto_Sans_Regular}) format("opentype");
            font-weight: normal;
            font-style: normal;
            font-display: swap;
            unicode-range: U+4E00-U+9FBF, U+3040-U+309F, U+30A0-U+30FF;
        }

        /* @font-face {
            font-family: 'noto-bold';
            src: url(${Noto_Sans_Bold}) format("opentype");
            font-weight: normal;
            font-style: normal;
            font-display: swap;
        } */

        *,
        ::after,
        ::before {
            box-sizing: border-box;
        }
        
        html,body,div,span,applet,object,iframe,
        h1,h2,h3,h4,h5,h6,p,blockquote,pre,
        a,abbr,acronym,address,big,
        cite,code,del,dfn,em,img,ins,
        kbd,q,s,samp,small,strike,strong,
        sub,sup,tt,var,b,u,i,center,
        dl,dt,dd,ol,ul,li,
        fieldset,form,label,legend,table,
        caption,tbody,tfoot,thead,tr,th,td,
        article,aside,canvas,details,embed,
        figure,figcaption,footer,header,
        hgroup,menu,nav,output,ruby,section,
        summary,time,mark,audio,video {
            margin: 0;
            padding: 0;
            border: 0;
            font-size: 100%;
            font: inherit;
            vertical-align: baseline;
            color: #fff;
        }
        /* HTML5 display-role reset for older browsers */
        article,
        aside,
        details,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        menu,
        nav,
        section {
            display: block;
        }
        body {
            line-height: 1;
        }
        blockquote,
        q {
            quotes: none;
        }
        blockquote:before,
        blockquote:after,
        q:before,
        q:after {
            content: "";
            content: none;
        }
        table {
            border-collapse: collapse;
            border-spacing: 0;
        }
        a {
            text-decoration: none;
            // color: #;
        }
        ul,
        ol {
            margin-left: 20px;
        }
        
        p {
            overflow-wrap: break-word;
            word-break: break-word;
            white-space: pre-wrap;
        }

        .blog-body {
            .heading {
                margin-bottom: 20px;
            }
            & > .rich-text-hr {
                margin-top: unset;
            }
            
        }

        .rich-text-p {
            font-size: 1rem;
            font-family: ${fonts.regular};
            overflow-wrap: break-word;
            word-break: break-word;
            white-space: pre-wrap;
            line-height: 24px;
            letter-spacing: -1.5px;
            margin-bottom: 15px;
        }

        .heading {
            font-weight: bold;
            font-family: ${fonts.bold};
            color: ${colors.gold};
            span{
                color: ${colors.gold};
                line-height: normal;
            }
        }
        
        .rich-text-italic {
            font-family: ${fonts.italic};
        }
        
        .rich-text-bold {
            font-family: ${fonts.bold};
            color: ${colors.gold};
            font-weight: 500;
        }

        main{
            min-height: 70vh;
            background: ${colors.black};
        }

        .page{
            header{
                overflow: hidden;
            }
            position: relative;
        }

        .container {
            width: 100%;
            margin: 0 auto;
            padding: 0 16px;
        }

        .container-xl {
            max-width: 1260px;
        }
        .container-lg {
            max-width: 1046px;
        }
        .container-md {
            max-width: 832px;
        }
        .container-sm {
            max-width: 571px;
        }

        .container-lg-md {
            max-width: calc(923px + 32px);
        }

        .rich-text-hr {
            border: 1px solid #707070;
            margin: 60px 0;
        }

        .content{
            padding-bottom: 120px;
            ${desktopBp}{
                margin-top: 55px;
                padding-bottom: 80px;
            }
        }

        .desktop_only{
            display: block;
            ${desktopBp}{
                display: none;
            }
        }

        .mobile_only{
            display: none;
            ${desktopBp}{
                display: block;
            }
        }
        .heading-1{
            font-size: 2.25rem;
            line-height: 2.75rem;
        }
        .heading-2{
            font-size: 2rem;
            line-height: 2.5rem;
        }
        .heading-3{
            font-size: 1.75rem;
            line-height: 2.25rem;
        }
        .heading-4{
            font-size: 1.375rem;
            line-height: 1.875rem;
        }
        .heading-5{
            font-size: 1.25rem;
            line-height: 1.75rem;
        }

        .overflow {
            overflow: hidden;
        }

        .rich-text-a{
            text-decoration: underline;
        }

        strong {
            overflow-wrap: break-word;
            word-break: break-word;
            white-space: pre-wrap;
        }

        .uc-embed-opened-content h5, .uc-embed-opened-content div, .uc-embed-opened-content p, .uc-embed-subelement-item-list, .uc-embed-subelement-item-list-element, .uc-a  {
            color: #000;
            }

        .section-breadcrumb + .section-content {
            .heading-1{
                ${desktopBp}{
                    font-size: 2rem;
                    text-align: left;
                }
            }
        }
    `

    return (
        <div className='page'>
            <GlobalStyles />
            <Header />
            <main>
                {children}
            </main>
            <Footer />
        </div>
    )
}

export default Layout
