export const mobileBp = `@media(min-width: 768px)`

export const desktopBp = `@media(max-width: 769px)`

export const colors = {
    black: '#000D0D',
    white: '#fff',
    defaultGrey: '#272A31',
    gold: '#D3A129'
}

export const fonts = {
    bold: 'noto-regular',
    regular: 'noto-regular',
    italic: 'noto-regular',
}

export const alignment = {
    title_center: 'center',
    title_right: 'right',
    title_left: 'left'
}

export const desktopVw = (px) => {
    return `${(px / 1366) * 100}vw`
}