import React from "react"
import Layout from "./src/components/Layout/Layout"
import Scripts from "./src/components/Layout/Scripts"
//import Scripts from "./src/components/Layout/Script"
//import Scripts from "./src/components/Layout/Scripts"
//import "./src/styles/main.scss"

export const wrapPageElement = ({ element }) => {
    return (
        <>
            <Scripts />
            <Layout>{element}</Layout>
        </>
    )
}